import $ from 'jquery';
import _ from 'underscore';
import asyncLoader from 'utils/async-loader';
import trackerDatalab from 'utils/tracker-datalab';
import serverTime from 'utils/server-time';
import util from 'utils/util';
import translation from 'utils/translation';
import darklaunch from 'utils/darklaunch';


var login = {
    init: function () {

        // if this is a popup, make sure it's tall enough.
        if (window.toolbar && !window.toolbar.visible && $(window).outerHeight() < 600) {
            window.resizeTo($(window).outerWidth(), 600);
        }

        // Kinesis tracking
        serverTime.init();
        trackerDatalab.init('body');

        var urlParams = hs.util.parseQueryString(location.search);
        var $forms = $('._emailPasswordForm, ._signupForm, ._emailSsoForm, ._linkAccountForm, ._confirmationEmailForm, ._googleAuthenticatorForm');
        var $requiredInputs = $('._requiredInput');
        var $emailInput = $('._emailInput');
        var $passwordInput = $('._passwordInput');
        var debouncedGoogleAuthenticatorFn;
        var lastEmailValueOnChange;
        var lastAutofillEmail = $emailInput.val();

        var $loginEmailInput = $("#loginEmailInput");
        var $loginPasswordInput =  $("#loginPasswordInput");
        var $googleInput =  $("#loginGoogleAuthenticatorInput");
        var $ssoInput =  $("#ssoEmailInput");
        var $createEmailInput =  $("#createEmailInput");
        var $createPasswordInput =  $("#createPasswordInput");
        var $newPasswordInput = $('._passwordInputNew');
        var $confirmPasswordInput = $('._passwordInputNewConfirmation');

        var inputsArray = [$loginEmailInput, $loginPasswordInput, $googleInput, $ssoInput, $createEmailInput, $createPasswordInput]

        inputsArray.forEach(input => {
            if(input !== undefined){
                var $inlineErrorContainer = input.siblings('._inlineErrorContainer');
                var $inlineErrorTitle = $inlineErrorContainer.find('._inlineErrorTitle')
                var $inlineErrorMessage = $inlineErrorContainer.find('._inlineErrorMessage')
                var $inputId = input?.selector?.substr(1, input?.selector?.length)

                if($inputId){
                    $inlineErrorTitle.attr('id', `inlineErrorTitle_${$inputId}`)
                    $inlineErrorMessage.attr('id', `inlineErrorMessage_${$inputId}`)
                    input.attr('aria-describedby', `inlineErrorTitle_${$inputId} inlineErrorMessage_${$inputId}`)
                }
            }
        })

        _.each($forms, login.toggleSubmitBtn);

        // form submission
        $('._submit').click(function (e) {
            var $btn = $(this);
            if ($btn.hasClass('green') && login.validateForm(this)) {
                if (login.isIframe() && $(this).hasClass('_ajaxCreateAccount')) {
                    login.ajaxCreateAccount($btn);
                } else {
                    $btn.closest('form').submit();
                }
            }
            e.preventDefault();
        });

        $forms.keypress(function (ev) {
            if (ev.which === 13) {
                ev.preventDefault();
                var $form = $(this);
                if (login.validateForm($form) && lastEmailValueOnChange === $emailInput.val()) {
                    if (login.isIframe() && $(this).children(["._submit"]).hasClass('_ajaxCreateAccount')) {
                        login.ajaxCreateAccount($form);
                    } else {
                        $form.submit();
                    }
                }
            }
        });

        if ($newPasswordInput.length && $confirmPasswordInput.length) {
            hs.util.checkNewPasswordPolicy($newPasswordInput, $confirmPasswordInput);
        }

        $newPasswordInput.keyup(function (event) {
            // don't check password if user pressed enter or tab
            var resetPasswordForm = $('#resetPasswordForm').length
            if (event.keyCode != 13 && event.keyCode != 9) {
                if (darklaunch.isFeatureEnabled('ID-3150-ADD-RESET-PASSWORD-CONFIRMATION') && resetPasswordForm) {
                    hs.util.checkNewPasswordPolicy($(this), $confirmPasswordInput);
                } else {
                    hs.util.checkPasswordPolicy($(this));
                }
            }
        });

        if (darklaunch.isFeatureEnabled('ID-3150-ADD-RESET-PASSWORD-CONFIRMATION')) {
            $confirmPasswordInput.keyup(function (event) {
                if (event.keyCode != 13 && event.keyCode != 9) {
                        var newPassword = $('._passwordInputNew')
                        hs.util.checkNewPasswordPolicy(newPassword, $(this));
                    }
            });
        }

        $requiredInputs.on("keyup blur input", _.debounce(function () {
            var resetPasswordForm = $('#resetPasswordForm').length
            if(darklaunch.isFeatureEnabled('ID-3150-ADD-RESET-PASSWORD-CONFIRMATION') && resetPasswordForm) {
                login.toggleSubmitResetPasswordButton(this);
            } else {
                login.toggleSubmitBtn(this)
            }
        }, 100));

        $emailInput.on('change', function () {
            lastEmailValueOnChange = $emailInput.val();
            var isEmailValid = login.validateEmailField(this);
            login.updateGoogleAuthenticator(this);
            //SSO case just shows email address so here we need to trigger setSubmitBtnEnabled
            var $ssoBlock = $('._ssoBlock');
            if ($ssoBlock.length && !$ssoBlock.is(':hidden')) {
                login.setSubmitBtnEnabled(isEmailValid, this);
            }
        }).on('input', function () {
            var email = $emailInput.val();
            var password = $passwordInput.val();

            //check to see if the email & password inputs have been autofilled as a result of user input
            _.defer(function () {
                var deferredEmail = $emailInput.val();
                if (deferredEmail !== email && deferredEmail !== lastAutofillEmail) {
                    lastAutofillEmail = deferredEmail;
                    $emailInput.trigger('change');
                } else if (deferredEmail !== lastEmailValueOnChange) {
                    if (!_.isFunction(debouncedGoogleAuthenticatorFn)) {
                        debouncedGoogleAuthenticatorFn = _.debounce(function () {
                            lastEmailValueOnChange = $emailInput.val();
                            login.updateGoogleAuthenticator($emailInput);
                        }, 200);
                    }

                    debouncedGoogleAuthenticatorFn();
                }

                var deferredPassword = $passwordInput.val();
                if (deferredPassword !== password) {
                    $passwordInput.trigger('change');
                }
            });
        });

        $('._googleAuthenticatorSkipLink').click(function (e) {
            $('._googleAuthenticatorInput').closest('.inputWrapper').hide();
            login.validateForm(this);
            e.preventDefault();
        });


        $('._forgotPassword').click(function (e) {
            var loc = '/reset-password';
            document.location.href = loc;
            e.preventDefault();
        });

        $('._showSSO').click(function (e) {
            $emailInput.val('');
            $('._notification').hide();
            $('._ssoBlock').show();
            $('._socialSignInBlock').hide();
            $('._mainLoginBlock').hide();
            $('._createAccountBlock').hide();

            $('input[autofocus="autofocus"]').focus();
            e.preventDefault();
        });

        $('._showSocialSignIn').click(function (e) {
            $('._notification').hide();
            $('._socialSignInBlock').show();
            $('._ssoBlock').hide();
            $('._mainLoginBlock').hide();
            $('._createAccountBlock').hide();

            $('input[autofocus="autofocus"]').focus();
            e.preventDefault();
        });

        $('._showCreateAccountForm, ._showLoginForm').click(function (e) {
            $('._notification').hide();
            $('._ssoBlock').hide();
            $('._socialSignInBlock').hide();
            $('input[autofocus="autofocus"]').focus();
            e.preventDefault();
        });
        $('._showCreateAccountForm').click(function (e) {
            $('._mainLoginBlock').hide();
            $('._createAccountBlock').show();
            $('input[autofocus="autofocus"]').focus();
            e.preventDefault();
        });
        $('._showLoginForm').click(function (e) {
            $('._mainLoginBlock').show();
            $('._createAccountBlock').hide();
            $('input[autofocus="autofocus"]').focus();
            e.preventDefault();
        });

        // Facebook social login
        $('._socialLoginFacebook').click(function () {
            if (login.isIframe()) {
                window.open('social-auth-popup?method=facebook', 'name', 'height=600,width=450');
            } else {
                var pathname = document.location.pathname;
                var params = '?method=facebook';
                document.location.href = encodeURI(pathname.concat(params));
            }
        });

        // Twitter social login
        $('._socialLoginTwitter').click(function () {
            if (login.isIframe()) {
                window.open('social-auth-popup?method=twitter', 'name', 'height=600,width=450');
            } else {
                var pathname = document.location.pathname;
                var params = '?method=twitter';
                document.location.href = encodeURI(pathname.concat(params));
            }
        });

        // Google login
        $('._socialLoginGoogle').click(function () {
            if (login.isIframe()) {
                window.open('social-auth-popup?method=google', 'name', 'height=600,width=450');
            } else {
                var pathname = document.location.pathname;
                var params = '?method=google';
                document.location.href = encodeURI(pathname.concat(params));
            }
        });

        // Apple social login
        $('._socialLoginApple').click(function () {
            if (login.isIframe()) {
                window.open('social-auth-popup?method=apple', 'name', 'height=600,width=450');
            } else {
                var pathname = document.location.pathname;
                var params = '?method=apple';
                document.location.href = encodeURI(pathname.concat(params));
            }
        });

        // LinkedIn social login
        $('._socialLoginLinkedin').click(function () {
            if (login.isIframe()) {
                window.open('social-auth-popup?method=linkedin', 'name', 'height=600,width=450');
            } else {
                const pathname = document.location.pathname;
                const params = '?method=linkedin';
                document.location.href = encodeURI(pathname.concat(params));
            }
        });

        // Verify account
        $('._socialVerify').click(function () {
            document.location.href = '/login?snid=' + $(this).data("snid");
        });

        $('._snProfileDropdown').click(function () {
            $('._moreSnProfiles').toggle();
            $('._snProfileDropdown').toggle();
        });

        hs.recaptcha.init(login);

        // Link account page
        var $linkAccountLogin = $('._linkAccount ._login'),
            $linkAccountCreate = $('._linkAccount ._create');
        $('._linkAccount ._connectNew').click(function (e) {
            e.preventDefault();
            e.stopPropagation();

            $linkAccountLogin.hide();
            $linkAccountCreate.show();
            login.toggleSubmitBtn($linkAccountCreate.find('form'));
        });

        $('._linkAccount ._signIn').click(function (e) {
            e.preventDefault();
            e.stopPropagation();

            $linkAccountCreate.hide();
            $linkAccountLogin.show();
            login.toggleSubmitBtn($linkAccountLogin.find('form'));
        });

        // Log the user out when facebook auth has failed so they can try a different FB account next time
        if (urlParams['method'] === 'facebook' && urlParams['code']) {
            login.logoutFacebookUser();
        }

        // delay is necessary as auto-filled fields are empty on document.ready
        _.delay(function () {
            if ($emailInput.val()) {
                // this trigger will handle the placeholder text and the google authenticator check
                $emailInput.trigger('change');
                lastAutofillEmail = $emailInput.val();
            }

            if ($passwordInput.val()) {
                $passwordInput.trigger('change');
            }
        }, 50);

        // Add the redirect query string to the action of the form
        if (darklaunch.isFeatureEnabled('ID_1693_ENABLE_REDIRECT_HIDDEN_INPUT_LOGIN')) {
            login.addRedirectQueryParamInput(urlParams['redirect']);
        }
    },

    logoutFacebookUser: function () {
        //updated sdk url and passing in version number
        return asyncLoader('https://connect.facebook.net/en_US/sdk.js').then(function () {
            var initData = {
                appId: hs.fbAppId,
                status: true,
                cookie: true,
                xfbml: false
            };
            var version = hs.fbApiVersion;
            if (version) {
                initData.version = 'v' + version;
            }

            window.FB.init(initData);

            window.FB.getLoginStatus(function (response) {
                if (response.status && response.status === 'connected') {
                    window.FB.logout();
                }
            }, true);
        });
    },

    updateGoogleAuthenticator: function (target) {
        var $target = $(target);
        var $form = $target.closest('form');
        var $googleAuthenticatorInput = $form.find('._googleAuthenticatorInput');
        var email = $target.val();

        if ($googleAuthenticatorInput.length && hs.util.isEmailValid(email)) {
            ajaxCall({
                url: '/ajax/member/show-google-authentication?loginEmail=' + encodeURIComponent(email),
                type: 'post',
                success: function (data) {
                    $googleAuthenticatorInput.val('');
                    $googleAuthenticatorInput.closest('.inputWrapper').toggle(data.showGoogleAuth == 1);
                    login.toggleSubmitBtn(target);
                }
            }, 'qm');
        } else {
            $googleAuthenticatorInput.val('');
            $googleAuthenticatorInput.closest('.inputWrapper').toggle(false);
        }
    },

    validateRequiredFields: function (target) {
        var $form = $(target).closest('form');
        var allRequiredFieldsValid = true;

        var $requiredFields = $form.find('._requiredInput');

        _.each($requiredFields, function (input) {
            var $input = $(input);

            if ($input.is(':visible') && "" === $input.val()) {
                allRequiredFieldsValid = false;
            }
        });

        return allRequiredFieldsValid;
    },

    validateEmailField: function (target, ignoreCreateErrorBubble) {
        var $form = $(target).closest('form');
        var $input = $form.find('._emailInput');
        var $inlineErrorContainer = $input.siblings('._inlineErrorContainer');

        if (!$input.length || ($input.is(':hidden') && $form.is(':visible'))) {
            return true;
        }

        if (!$input[0].checkValidity()) {
            // show error bubble
            if (!ignoreCreateErrorBubble && $inlineErrorContainer.length) {
                $inlineErrorContainer.css("display", "flex");
                $inlineErrorContainer.find('._inlineErrorTitle').text(translation._("Invalid email"));
                $inlineErrorContainer.find('._inlineErrorMessage').text(translation._("Please enter a valid email address"));
                $input.attr('aria-invalid', 'true');
                $input.attr('aria-describedby', `inlineErrorTitle inlineErrorMessage`)
            }
            return false;
        } else {
            // remove inline error
            $inlineErrorContainer.css("display", "none");
            $input.attr('aria-invalid', 'false');
            return true;
        }
    },

    validateResetPassword: function(target, ignoreCreateErrorBubble, inputName, passwordConfirmationName) {
        var $form = $(target).closest('form');
        var $input = $form.find(inputName);
        var $confirmation = $form.find((passwordConfirmationName))
        var $inlineErrorContainer = $input.siblings('._inlineErrorContainer');

        if (!$input.length || ($input.is(':hidden') && $form.is(':visible'))) {
            return true;
        }

        var validationResult = hs.util.isResetPasswordValid($input.val()) && hs.util.checkNewPasswordPolicy($input, $confirmation)

        if (!validationResult) {
            // show inline error
            if (!ignoreCreateErrorBubble && $inlineErrorContainer.length) {
                $inlineErrorContainer.css("display", "flex");
                $inlineErrorContainer.find('._inlineErrorTitle').text(translation._("Invalid password"));
                $inlineErrorContainer.find('._inlineErrorMessage').text(translation._("Your password does not meet our password policy"));
            }
            return false;
        } else {
            // remove inline error
            $inlineErrorContainer.css("display", "none");
            return true;
        }
    },

    validatePassword: function(target, ignoreCreateErrorBubble, inputName) {
        var $form = $(target).closest('form');
        var $input = $form.find(inputName);
        var $inlineErrorContainer = $input.siblings('._inlineErrorContainer');

        if (!$input.length || ($input.is(':hidden') && $form.is(':visible'))) {
            return true;
        }

        if (!hs.util.isPasswordValid($input.val())) {
            // show inline error
            if (!ignoreCreateErrorBubble && $inlineErrorContainer.length) {
                $inlineErrorContainer.css("display", "flex");
                $inlineErrorContainer.find('._inlineErrorTitle').text(translation._("Invalid password"));
                $inlineErrorContainer.find('._inlineErrorMessage').text(translation._("Your password is too short"));
            }
            return false;
        } else {
            // remove inline error
            $inlineErrorContainer.css("display", "none");
            return true;
        }
    },

    validatePasswordField: function (target, ignoreCreateErrorBubble) {
        var inputName = '._passwordInput';
        return login.validatePassword(target, ignoreCreateErrorBubble, inputName);
    },

    validatePasswordNewField: function (target, ignoreCreateErrorBubble) {
        var inputName = '._passwordInputNew';
        return login.validatePassword(target, ignoreCreateErrorBubble, inputName);
    },

    validateGoogleAuthenticatorField: function (target) {
        var $form = $(target).closest('form');
        var $input = $form.find('._googleAuthenticatorInput');
        var $inlineErrorContainer = $input.siblings('._inlineErrorContainer');

        if (!$input.length || ($input.is(':hidden') && $form.is(':visible'))) {
            return true;
        }

        if (!hs.util.isGoogleAuthenticatorValid($input.val())) {
            // show inline error
            if ($inlineErrorContainer.length) {
                $inlineErrorContainer.css("display", "flex");
                $inlineErrorContainer.find('._inlineErrorTitle').text(translation._("Invalid code"));
                $inlineErrorContainer.find('._inlineErrorMessage').text(translation._("Please enter a valid Google Authenticator code"));
                document.getElementById('verifyGoogleAuthenticatorInput').setAttribute("aria-invalid", "true");
            }
            return false;
        } else {
            // remove inline error
            $inlineErrorContainer.css("display", "none");
            document.getElementById('verifyGoogleAuthenticatorInput').setAttribute("aria-invalid", "false");
            return true;
        }
    },

    validateForm: function (target) {
        login.toggleSubmitBtn(target);

        var isValidForm = '';
        if ($('._passwordInputNew').length) {
            isValidForm =
                login.validateRequiredFields(target) &&
                login.validateEmailField(target) &&
                login.validatePasswordField(target) &&
                login.validatePasswordNewField(target) &&
                login.validateGoogleAuthenticatorField(target);
        } else {
            isValidForm =
                login.validateRequiredFields(target) &&
                login.validateEmailField(target) &&
                login.validatePasswordField(target) &&
                login.validateGoogleAuthenticatorField(target);
        }

        return isValidForm;
    },

    toggleSubmitBtn: function (target) {
        login.validatePasswordField(target, true);

        // In order to enable the submit button we only strictly care that the
        // email field is valid and that required fields are not empty.
        var enableSubmitBtn =
            login.validateRequiredFields(target) &&
            login.validateEmailField(target, true);

        login.setSubmitBtnEnabled(enableSubmitBtn, target);
    },

    toggleSubmitResetPasswordButton: function(target) {
        var inputName = '#newPasswordInput'
        var confirmationName = '#newPasswordInputConfirmation'
        var validateResetPassword = login.validateResetPassword(target, false, inputName, confirmationName);
        login.setSubmitBtnEnabled(validateResetPassword, target);
    },

    isEmailPasswordAutoFilled: function () {
        return $("#loginEmailInput").css('appearance') === 'menulist-button' && $("#loginPasswordInput").css('appearance') === 'menulist-button';
    },
    detectChromeAutofill: true,
    setSubmitBtnEnabled: function (enabled, target) {
        var $form = $(target).closest('form');
        var setAttributesAndClasses = function (enabled) {
            var $submitBtn = $form.find('._submit');

            // toggle aria-disabled on submit button
            if (!enabled) {
                $submitBtn.attr("aria-disabled", true);
                $submitBtn.attr("tabindex", '-1')
                $submitBtn.attr("aria-describedby", "inlineErrorContainer")
            } else {
                $submitBtn.attr("tabindex", '0')
                $submitBtn.attr("aria-disabled", false);
                $submitBtn.removeAttr("aria-describedby")
            }

            $submitBtn.toggleClass('disabled', !enabled);
            $submitBtn.toggleClass('green', enabled);
        }

        if (login.isChrome() && $form.is('._emailPasswordForm')) {
            setAttributesAndClasses(enabled)

            if (login.detectChromeAutofill) {
                login.detectChromeAutofill = false

                var intervalId;
                var triesRemaining = 10;

                intervalId = setInterval(function () {
                    if (login.isEmailPasswordAutoFilled()) {
                        setAttributesAndClasses(true)
                        clearInterval(intervalId)
                    }

                    triesRemaining--;

                    if (triesRemaining === 0) {
                        clearInterval(intervalId)
                    }
                }, 200)
            }

        } else {
            setAttributesAndClasses(enabled)
        }

    },

    isChrome: function () {
        var agent = navigator.userAgent.toLowerCase();
        return (agent.search("chrome") > -1 && agent.search("safari") > -1);
    },

    isIframe: function () {
        if (typeof window.top === 'object' && typeof window.self === 'object') {
            return (window.top.location !== window.self.location);
        }
        return false;
    },

    ajaxCreateAccount: function (element) {
        var notification = $('._ajax-notification');
        var errorNotification = function (message) {
            notification.show();
            notification.removeClass('success');
            notification.addClass('error');
            notification.html(message);
        };
        var successNotification = function (message) {
            notification.show();
            notification.removeClass('error');
            notification.addClass('success');
            notification.html(message);
        };
        var postData = element.closest('form').serialize();
        ajaxCall({
            type: 'POST',
            url: "/ajax/member/ajax-new-signup",
            data: postData,
            success: function (data) {
                if (data.success == '1') {
                    successNotification("Success!");
                    top.location = hs.util.getUrlRoot() + "/account-setup";
                }
                else
                {
                    // Update the token for new session
                    $("._signupForm input[name='token']").val(data.token);
                    // Just show the form errors
                    if (data.error) {
                        // Redirect spammers
                        if (data.errorCode == 403) {
                            util.doRedirect('/security/unusual-activity');
                        }
                        errorNotification(data.error);
                    }
                }
            },
            error: function () {
                errorNotification("Could not complete sign up, please try again.");
            }
        }, 'qmNoAbort');
    },

    // Add the redirect query string hidden input to the email form
    // This is necessary since Hootsuite uses hashs (#) as important navigation URLs
    // However, hashs are NOT send to the backend by the browser, so we are forcing it in the query
    addRedirectQueryParamInput: function(redirectValue) {
        if (!redirectValue) return;

        let redirectURL = redirectValue;
        if (window.location.hash) {
            redirectURL = redirectURL + window.location.hash;
        }

        const redirectInput = document.createElement('input');
        redirectInput.setAttribute("type", "hidden");
        redirectInput.setAttribute("name", "redirect");
        redirectInput.setAttribute("value", redirectURL);

        const emailForm = document.querySelector('._emailPasswordForm')
        emailForm.appendChild(redirectInput.cloneNode(true));

        const ssoForm = document.querySelector('._emailSsoForm')
        ssoForm.appendChild(redirectInput.cloneNode(true));
    }
};

export default login;

